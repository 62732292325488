$(document).ready(function () {
    $('#nav ul li a').click(function (e) {
        //e.preventDefault();

        // $("#nav ul li a").removeClass("active");
        // $(this).addClass("active");

        var url = $(this).attr('dataid');
        
        if (typeof url != "undefined") {
        
            var str = url.substring(url.indexOf('#') + 1);
            if (str != '' && $('#' + str).length > 0) {
                //console.log(url.substring(url.indexOf('#')+1));
                $('html, body').animate({
                    scrollTop: $('#' + url.substring(url.indexOf('#') + 1)).offset().top - 70
                }, 500);
            }
        }
    });

    /***** responsive menu ********/
    $("#nav a.menu-icon").click(function (e) {
        e.preventDefault();
        $("#nav ul").slideToggle(300);
    });
    if ($(window).width() <= 768) {
        $("#nav ul li a").click(function () {
            $("#nav ul").fadeOut(300);
        });
    }


    if ($(window).width() <= 768) {
        $("#banner_section #tas_nav").click(function (e) {
            $("#banner_section .tabs_block ul.nav").slideToggle(300);
        });

        $("#banner_section .tabs_block ul li a").click(function (e) {
            $("#banner_section .tabs_block ul.nav").fadeOut(300);
        });
    }

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 300) {
            $(".header-block").addClass("isStuck");
            $(".header-block").addClass("fadeInDown");
            $(".header-block").addClass("wow");
            $(".header-block").addClass("animated");
        }
        else {
            $(".header-block").removeClass("isStuck");
            $(".header-block").removeClass("fadeInDown");
            $(".header-block").removeClass("wow");
            $(".header-block").removeClass("animated");
        }

    });


    $('.bxslider').bxSlider({
        auto: true,
        pager: true,
        autoControls: false,
        speed: 1500,
        mode: 'fade',
        autoHover: true
    });

    /*$("#owl_testimonial").owlCarousel(
     {
        items : 1,
        lazyLoad : true,        
        loop: true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        dots: true,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        nav : true,
        navText : ["<div class='left-navigation'></div>", "<div class='right-navigation'></div>"],
        responsiveClass:true,
        responsive:
        {
            0:{
                items:1
            },
            640:{
                items:1
            },
            641:{
                items:1
            },  
            1024:{
                items:1
            } 
        }      
    });*/


     $('#scrolldown').click(function(e) {
        //e.preventDefault();


        var url = $(this).attr('dataid');
        
        var str = url.substring(url.indexOf('#')+1);
        if(str != '' && $('#'+str).length > 0) {
            //console.log(url.substring(url.indexOf('#')+1));
          $('html, body').animate({
              scrollTop: $('#' + url.substring(url.indexOf('#')+1)).offset().top - 0
          }, 900);
        }
      });
     $('#scrolldownbtn').click(function(e) {
        //e.preventDefault();


        var url = $(this).attr('dataid');
        
        var str = url.substring(url.indexOf('#')+1);
        if(str != '' && $('#'+str).length > 0) {
            //console.log(url.substring(url.indexOf('#')+1));
          $('html, body').animate({
              scrollTop: $('#' + url.substring(url.indexOf('#')+1)).offset().top - 20
          }, 900);
        }
      });

    if ($('#datepicker').length > 0) {
        // $('#datepicker').datepicker({
        //     uiLibrary: 'bootstrap4'
        // });
    }



    // slick carousel
    $('.slider').slick({

        vertical: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        verticalSwiping: true,
        arrows: true
    });

    $(".bid_request_head .toggal_arrow").click(function (e) {
        $(this).toggleClass('open');
        $data_id = $(this).attr('data-id');

        e.preventDefault();
        $("#" + $data_id).toggle(300);
    });

});

$(function () {
    if ($('#slider-range').length > 0) {
        $("#slider-range").slider({
            step: 1,
            range: true,
            animate: "fast",
            min: 0,
            max: 800,
            values: [20, 800],
            slide: function (event, ui) {
                $("#amount_min").val(ui.values[0]);
                $("#amount_max").val(ui.values[1]);
            }
        });
        $("#amount_min").val($("#slider-range").slider("values", 0));
        $("#amount_max").val($("#slider-range").slider("values", 1));
    }
});
$(function () {
    if ($('#slider-range2').length > 0) {
        $("#slider-range2").slider({
            step: 1,
            range: true,
            animate: "fast",
            min: 0,
            max: 450,
            values: [20, 450],
            slide: function (event, ui) {
                $("#amount_min2").val("$" + ui.values[0]);
                $("#amount_max2").val("$" + ui.values[1]);
            }
        });
        $("#amount_min2").val("$" + $("#slider-range2").slider("values", 0));
        $("#amount_max2").val("$" + $("#slider-range2").slider("values", 1));
    }
});
$(function () {
    if ($('#slider-range3').length > 0) {
        $("#slider-range3").slider({
            step: 1,
            range: true,
            animate: "fast",
            min: 0,
            max: 250,
            values: [20, 250],
            slide: function (event, ui) {
                $("#amount_min3").val(ui.values[0]);
                $("#amount_max3").val(ui.values[1]);
            }
        });
        $("#amount_min3").val($("#slider-range3").slider("values", 0));
        $("#amount_max3").val($("#slider-range3").slider("values", 1));
    }
});